export function IsNumber(input: string) {
  if (typeof input != "number") {
    if (!input.IsNumber()) {
      return "กรุณากรอกตัวเลขเท่านั้น";
    } else {
      return true;
    }
  } else {
    return true;
  }
}

export function IsLengthOver(input: string, length: number) {
  return input.trim().length <= length || `กรุณากรอกไม่เกิน ${length} ตัว`;
}

export function IsEmpty(input: string | number) {
  if (typeof input != "number") {
    if (input.trim() == "") {
      return `กรุณากรอกข้อมูล`;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

export function IsZero(input: string | number) {
  if (typeof input == "string") {
    return input.trim().ToNumber() > 0 || `กรุณากรอกตัวเลขที่มากกว่า 0`;
  } else {
    return input > 0 || `กรุณากรอกตัวเลขที่มากกว่า 0`;
  }
}
