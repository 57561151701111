









































































































































































































































import { GetDateLengthDiffer } from "@/helper/shareHelper";
import { IsNumber, IsLengthOver, IsEmpty, IsZero } from "@/helper/textInputValidateHelper";
import { PromoCodeGenTemplate } from "@/model/promoCode/promoCodeModel";
import { ValidateStringNumberCondition } from "@/helper/promoCodeHelper";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({ name: "ModalAutoGenPromoCode" })
export default class ModalAutoGenPromoCode extends Vue {
  private showModal = true;
  private IsEmpty = IsEmpty;
  private IsZero = IsZero;
  private IsNumber = IsNumber;
  private IsLengthOver = IsLengthOver;
  private isAllError = false;
  private modalExpireDate = false;
  private modalStartDate = false;
  private promoCodeGenTemplate: PromoCodeGenTemplate = {
    format: {
      value: "",
      isValidateError: false,
    },
    quotaPerCode: {
      value: "",
      isValidateError: false,
    },
    numberOfTimesCreate: {
      value: "",
      isValidateError: false,
    },
    fixClientId: {
      isCheck: false,
      id: {
        value: "",
        isValidateError: false,
      },
    },
    fixFirmId: {
      isCheck: false,
      id: {
        value: "",
        isValidateError: false,
      },
    },
    fixMerchantId: {
      isCheck: false,
      id: {
        value: "",
        isValidateError: false,
      },
    },
    fixUserId: {
      isCheck: false,
      id: {
        value: "",
        isValidateError: false,
      },
    },
    startDate: {
      value: "",
      isValidateError: false,
      modal: false,
    },
    expireDate: {
      value: "",
      isValidateError: false,
      modal: false,
    },
  };

  @Watch("showModal")
  showModalChanged(newVal: boolean) {
    if (!newVal) {
      this.HideModal();
    }
  }

  public HideModal() {
    this.$emit("update:showModal", false);
  }
  public ValidateGenCode() {
    let isAllError = false;
    if (!this.promoCodeGenTemplate.startDate.value) {
      this.promoCodeGenTemplate.startDate.isValidateError = true;
      isAllError = true;
    } else {
      this.promoCodeGenTemplate.startDate.isValidateError = false;
    }
    if (!this.promoCodeGenTemplate.expireDate.value) {
      this.promoCodeGenTemplate.expireDate.isValidateError = true;
      isAllError = true;
    } else {
      this.promoCodeGenTemplate.expireDate.isValidateError = false;
    }
    if (!this.promoCodeGenTemplate.expireDate.isValidateError && !this.promoCodeGenTemplate.startDate.isValidateError) {
      if (
        GetDateLengthDiffer(this.promoCodeGenTemplate.startDate.value, this.promoCodeGenTemplate.expireDate.value) > 0
      ) {
        this.promoCodeGenTemplate.startDate.isValidateError = true;
        this.promoCodeGenTemplate.expireDate.isValidateError = true;
        isAllError = true;
      }
    }
    if (this.promoCodeGenTemplate.format.value.trim() == "") {
      isAllError = true;
      this.promoCodeGenTemplate.format.isValidateError = true;
    } else {
      this.promoCodeGenTemplate.format.isValidateError = false;
    }
    if (this.promoCodeGenTemplate.quotaPerCode.value.trim() == "") {
      isAllError = true;
      this.promoCodeGenTemplate.quotaPerCode.isValidateError = true;
    } else {
      this.promoCodeGenTemplate.quotaPerCode.isValidateError = false;
    }
    if (this.promoCodeGenTemplate.numberOfTimesCreate.value.trim() == "") {
      isAllError = true;
      this.promoCodeGenTemplate.numberOfTimesCreate.isValidateError = true;
    } else {
      this.promoCodeGenTemplate.numberOfTimesCreate.isValidateError = false;
    }
    if (this.promoCodeGenTemplate.fixUserId.isCheck) {
      if (ValidateStringNumberCondition(this.promoCodeGenTemplate.fixUserId.id.value)) {
        this.promoCodeGenTemplate.fixUserId.id.isValidateError = true;
        isAllError = true;
      } else {
        this.promoCodeGenTemplate.fixUserId.id.isValidateError = false;
      }
    } else {
      this.promoCodeGenTemplate.fixUserId.id.isValidateError = false;
    }
    if (this.promoCodeGenTemplate.fixClientId.isCheck) {
      if (ValidateStringNumberCondition(this.promoCodeGenTemplate.fixUserId.id.value)) {
        this.promoCodeGenTemplate.fixClientId.id.isValidateError = true;
        isAllError = true;
      } else {
        this.promoCodeGenTemplate.fixClientId.id.isValidateError = false;
      }
    } else {
      this.promoCodeGenTemplate.fixClientId.id.isValidateError = false;
    }
    if (this.promoCodeGenTemplate.fixMerchantId.isCheck) {
      if (ValidateStringNumberCondition(this.promoCodeGenTemplate.fixUserId.id.value)) {
        this.promoCodeGenTemplate.fixMerchantId.id.isValidateError = true;
        isAllError = true;
      } else {
        this.promoCodeGenTemplate.fixMerchantId.id.isValidateError = false;
      }
    } else {
      this.promoCodeGenTemplate.fixMerchantId.id.isValidateError = false;
    }
    if (this.promoCodeGenTemplate.fixFirmId.isCheck) {
      if (ValidateStringNumberCondition(this.promoCodeGenTemplate.fixUserId.id.value)) {
        this.promoCodeGenTemplate.fixFirmId.id.isValidateError = true;
        isAllError = true;
      } else {
        this.promoCodeGenTemplate.fixFirmId.id.isValidateError = false;
      }
    } else {
      this.promoCodeGenTemplate.fixFirmId.id.isValidateError = false;
    }
  }
}
