















































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DropdownCampaign from "@/components/dropdown/DropdownCampaign.vue";
import { GetPackageList } from "@/helper/packageHelper";
import PromoCodeConditionCreate from "./PromoCodeConditionCreate.vue";
import PromoCodeRequestByCreateDiscountOptions from "./PromoCodeRequestByCreateDiscountOptions.vue";
import PromoCodeCreateBasicAndAdvance from "./PromoCodeCreateBasicAndAdvance.vue";
import { ValidateCreatePromoCode, ValidateCreatePromoCampaign } from "@/helper/promoCodeHelper";
import {
  PromoCampaignModel,
  PromoCodeRequestCreateElementModel,
  PromoCodeRequestCreateListModel,
  PromoCampaignCreateTemplateModel,
} from "@/model/promoCode/promoCodeModel";
import { IsLengthOver, IsEmpty } from "@/helper/textInputValidateHelper";
import {
  PromoCampaignType,
  CustomerType,
  IsCommercial,
  PromoCampaignDiscountType,
  PackageMonth,
  LoadingStatusType,
} from "@/enums/enums";
import { namespace } from "vuex-class";

const StorePromoCodeRequest = namespace("PromoCodeRequest");

@Component({
  components: { DropdownCampaign, PromoCodeRequestByCreateDiscountOptions, PromoCodeCreateBasicAndAdvance },
  name: "PromoCodeRequestByCreate",
})
export default class PromoCodeRequestByCreate extends Vue {
  @Prop({}) promoCampaignCreateTemplate!: any;
  @Prop({}) requestDetail!: any;
  @Prop({}) campaignIndex!: number;
  @Prop({}) isEditDraft!: boolean;

  private LoadingStatusType = LoadingStatusType;
  private PromoCampaignType = PromoCampaignType;
  private IsLengthOver = IsLengthOver;
  private IsEmpty = IsEmpty;
  private type: "" | PromoCampaignType = "";

  private customerTypeList: Array<any> = [
    { value: CustomerType.Organic, text: "Organic" },
    { value: CustomerType.AFP, text: "AFP" },
    { value: CustomerType.BD, text: "BD" },
    { value: CustomerType.CSR, text: "CSR" },
    { value: CustomerType.Enterprise, text: "Enterprise" },
    { value: CustomerType.APIServices, text: "API services" },
    { value: CustomerType.PARC, text: "PARC" },
    { value: CustomerType.Test, text: "Test" },
    { value: CustomerType.ClientSupports, text: "Client supports" },
    { value: CustomerType.AFPFree, text: "AFPFree" },
    { value: CustomerType.BDFree, text: "BDFree" },
    { value: CustomerType.OtherInternalUses, text: "Other internal uses" },
    { value: CustomerType.Gifts, text: "Gifts" },
  ];
  private isCommercialList: Array<any> = [
    { value: IsCommercial.Yes, text: "Yes" },
    { value: IsCommercial.No, text: "No" },
  ];

  @StorePromoCodeRequest.Getter
  private getPromoCampaignRequest!: (
    campaignIndex: number
  ) => (campaignIndex: number) => PromoCodeRequestCreateElementModel;

  @StorePromoCodeRequest.Getter
  private getConvertPromoCodeLoadingStatus!: LoadingStatusType;

  get campaignRequestDetail(): PromoCampaignCreateTemplateModel {
    return this.requestDetail.campaignRequest;
  }

  @StorePromoCodeRequest.Action
  private UpdateCampaignRequestDetail!: ({
    campaignIndex,
    key,
    value,
  }: {
    campaignIndex: number;
    key: string;
    value: boolean;
  }) => void;

  @StorePromoCodeRequest.Action
  private UpdateCampaignRequestValue!: ({
    campaignIndex,
    key,
    value,
  }: {
    campaignIndex: number;
    key: string;
    value: boolean;
  }) => void;

  @StorePromoCodeRequest.Action
  private UpdateCampaignType!: ({ campaignIndex, type }: { campaignIndex: number; type: PromoCampaignType }) => void;

  @StorePromoCodeRequest.Mutation
  private ValidateCampaignRequest!: ({ campaignIndex }: { campaignIndex: number }) => void;

  private packageList = GetPackageList(true);

  get isDiscountTypeAmount() {
    return this.campaignRequestDetail.discountType === PromoCampaignDiscountType.Amount;
  }

  public OnChangeCreatePromoCampaign() {
    const input = {
      campaignIndex: this.campaignIndex,
    };
    this.ValidateCampaignRequest(input);
  }

  private UpdateCheckbox(campaignIndex: number, key: string, value: boolean) {
    this.UpdateCampaignRequestDetail({ campaignIndex, key, value });
  }

  private UpdateValue(campaignIndex: number, key: string, value: boolean) {
    this.UpdateCampaignRequestValue({ campaignIndex, key, value });
  }

  private DoUpdateCampaignType(type: PromoCampaignType) {
    this.UpdateCampaignType({ campaignIndex: this.campaignIndex, type });
  }
  created() {
    this.type = this.isEditDraft ? this.campaignRequestDetail.type : PromoCampaignType.Discount;
  }

  private IsDuplicateName() {
    return !this.requestDetail.campaignRequest.name.isValidateError || "ชื่อ Campaign ซ้ำ";
  }

  @Watch("campaignRequestDetail.type")
  campaignRequestDetailTypeChange(newVal: PromoCampaignType) {
    if (newVal != PromoCampaignType.Discount) {
      this.UpdateCheckbox(this.campaignIndex, "isRecurring", false);
    }
  }
}
