












































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  PromoCampaignDiscountType,
  PackageMonth,
  PromoCampaignType,
  PackagePeriodType,
  PeakPackage,
} from "@/enums/enums";
import { GetPackageList } from "@/helper/packageHelper";
import { IsNumber, IsLengthOver, IsEmpty, IsZero } from "@/helper/textInputValidateHelper";
import { PromoCampaignModel } from "@/model/promoCode/promoCodeModel";
import { namespace } from "vuex-class";

const StorePromoCodeRequest = namespace("PromoCodeRequest");

@Component({ name: "PromoCodeRequestByCreateDiscountOptions" })
export default class PromoCodeRequestByCreateDiscountOptions extends Vue {
  @Prop({}) campaignType!: number;
  @Prop({}) promoCampaignCreateTemplate!: any;
  @Prop({}) promoCampaignList!: Array<PromoCampaignModel>;
  @Prop({}) campaignIndex!: number;
  @Prop({}) isEditDraft!: boolean;

  private PromoCampaignType = PromoCampaignType;
  private PackagePeriodType = PackagePeriodType;
  private PromoCampaignDiscountType = PromoCampaignDiscountType;
  private modalDate = false;
  private campaignRequestDetail = this.promoCampaignCreateTemplate;
  private IsNumber = IsNumber;
  private IsLengthOver = IsLengthOver;
  private IsEmpty = IsEmpty;
  private IsZero = IsZero;

  private packageList: [] | Record<string, any>[] = [];
  private discountType = PromoCampaignDiscountType.None;
  private periodType = 0;

  @StorePromoCodeRequest.Action
  private UpdateCampaignRequestDetail!: ({
    campaignIndex,
    key,
    value,
  }: {
    campaignIndex: number;
    key: string;
    value: boolean;
  }) => void;

  @StorePromoCodeRequest.Action
  private UpdateCampaignRequestValue!: ({
    campaignIndex,
    key,
    value,
  }: {
    campaignIndex: number;
    key: string;
    value: boolean;
  }) => void;

  @StorePromoCodeRequest.Action
  private UpdateFormatMoney!: ({
    campaignIndex,
    key,
  }: {
    campaignIndex: number;
    key: "discountNumber" | "discountPercent" | "packagePrice";
  }) => void;

  @StorePromoCodeRequest.Mutation
  private ValidateCampaignRequest!: ({ campaignIndex }: { campaignIndex: number }) => void;
  @StorePromoCodeRequest.Mutation
  private ResetOneCampaignPrivilegeAndDiscount!: ({
    campaignIndex,
    isPeriodTypeChange,
  }: {
    campaignIndex: number;
    isPeriodTypeChange: boolean;
  }) => void;

  @StorePromoCodeRequest.Mutation
  private UpdatePackageExpireDateValue!: ({
    campaignIndex,
    key,
    value,
  }: {
    campaignIndex: number;
    key: string;
    value: string | boolean;
  }) => void;

  private UpdateFormatMoneyOnBlur(campaignIndex: number, key: "discountNumber" | "discountPercent") {
    this.UpdateFormatMoney({ campaignIndex, key });
  }

  private UpdateType(campaignIndex: number, key: string, value: any) {
    this.UpdateCampaignRequestDetail({ campaignIndex, key, value });
  }

  private UpdateValue(campaignIndex: number, key: string, value: any) {
    this.UpdateCampaignRequestValue({ campaignIndex, key, value });
  }

  private DoSetDateModalOpen() {
    const input = {
      campaignIndex: this.campaignIndex,
      key: "isShowModal",
      value: true,
    };
    this.UpdatePackageExpireDateValue(input);
  }

  private DoSetDateModalClose() {
    const input = {
      campaignIndex: this.campaignIndex,
      key: "isShowModal",
      value: false,
    };
    this.UpdatePackageExpireDateValue(input);
  }

  private UpdateExpireDate(value: string) {
    const input = {
      campaignIndex: this.campaignIndex,
      key: "value",
      value,
    };
    this.UpdatePackageExpireDateValue(input);
  }

  private periodList = [
    { value: PackagePeriodType.Month, text: "Month" },
    { value: PackagePeriodType.Day, text: "Day" },
    { value: PackagePeriodType.ExpiredDate, text: "Expired Date" },
  ];

  private discountTypeList = [
    { value: PromoCampaignDiscountType.Amount, text: "Discount Amount" },
    { value: PromoCampaignDiscountType.Percent, text: "Discount Percent" },
  ];
  private packageMonthList: [] | Array<Record<string, any>> = [];

  created() {
    this.packageMonthList = [
      { value: PackageMonth.OneMonth, text: "1 Month" },
      { value: PackageMonth.ThreeMonth, text: "3 Month" },
      { value: PackageMonth.TwelveMonth, text: "12 Month" },
      { value: PackageMonth.None, text: "Not specify" },
    ];
    this.packageList = GetPackageList(true);
    this.UpdateValue(this.campaignIndex, "packagePeriod", PackageMonth.OneMonth);
  }

  @Watch("campaignType")
  setPackageMonthList() {
    this.ResetPrivilegeAndDiscount();
    switch (this.campaignType) {
      case PromoCampaignType.Discount:
        this.packageMonthList = [
          { value: PackageMonth.OneMonth, text: "1 Month" },
          { value: PackageMonth.ThreeMonth, text: "3 Month" },
          { value: PackageMonth.TwelveMonth, text: "12 Month" },
          { value: PackageMonth.None, text: "Not specify" },
        ];
        this.packageList = GetPackageList(true);
        this.UpdateDiscountType(PromoCampaignDiscountType.Amount);
        this.UpdateValue(this.campaignIndex, "packagePeriod", PackageMonth.OneMonth);
        break;
      case PromoCampaignType.Package:
        this.packageMonthList = [
          { value: PackageMonth.OneMonth, text: "1 Month" },
          { value: PackageMonth.ThreeMonth, text: "3 Month" },
          { value: PackageMonth.TwelveMonth, text: "12 Month" },
        ];
        this.packageList = GetPackageList(true);
        this.UpdateType(this.campaignIndex, "packageId", 0);
        break;
      case PromoCampaignType.Extra:
        this.packageMonthList = [
          { value: PackageMonth.OneMonth, text: "1 Month" },
          { value: PackageMonth.ThreeMonth, text: "3 Month" },
          { value: PackageMonth.TwelveMonth, text: "12 Month" },
        ];
        this.packageList = GetPackageList();
        this.UpdateType(this.campaignIndex, "packageId", PeakPackage.Basic);
        this.UpdateValue(this.campaignIndex, "packagePeriod", PackageMonth.OneMonth);
    }
  }

  private UpdateDiscountType(e: PromoCampaignDiscountType) {
    this.UpdateType(this.campaignIndex, "discountType", e);
    this.ResetPrivilegeAndDiscount();
  }

  private UpdatePeriodType(e: PackagePeriodType) {
    this.UpdateType(this.campaignIndex, "periodType", e);
    this.ResetPrivilegeAndDiscount();
  }

  public ResetPrivilegeAndDiscount() {
    this.ResetOneCampaignPrivilegeAndDiscount({ campaignIndex: this.campaignIndex, isPeriodTypeChange: true });
  }

  public OnChangeCreatePromoCampaign() {
    this.ValidateCampaignRequest({ campaignIndex: this.campaignIndex });
  }
}
