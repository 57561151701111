












































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DropdownCampaign from "@/components/dropdown/DropdownCampaign.vue";
import PromoCodeConditionCreate from "./PromoCodeConditionCreate.vue";
import { ValidateCreatePromoCode, GetPromoCodeConditionText } from "@/helper/promoCodeHelper";
import { CreatePromoCodeType, LoadingStatusType } from "@/enums/enums";
import {
  PromoCampaignModel,
  PromoCampaignCreateBySelectBase,
  PromoCampaignRequest,
} from "../../../model/promoCode/promoCodeModel";
import PromoCodeCreateBasicAndAdvance from "./PromoCodeCreateBasicAndAdvance.vue";
import { namespace } from "vuex-class";

const StorePromoCodeRequest = namespace("PromoCodeRequest");
const StorePromoCode = namespace("PromoCode");
@Component({ components: { DropdownCampaign, PromoCodeCreateBasicAndAdvance }, name: "PromoCodeRequestBySelect" })
export default class PromoCodeRequestBySelect extends Vue {
  @Prop({}) requestDetail!: any;
  @Prop({}) campaignIndex!: number;
  @Prop({}) isEditDraft!: boolean;

  // use to keep promo code re render when data changes
  private selectedRequest: PromoCampaignModel = new PromoCampaignModel();
  private renderDropDown = false;
  @StorePromoCodeRequest.Getter
  private getPromoCampaignSelectedDetail!: (campaignIndex: number) => (campaignIndex: number) => Record<string, any>;

  @StorePromoCodeRequest.Getter
  private getConditionPromoCampaignSelectedDetail!: (campaignIndex: number) => (campaignIndex: number) => string;

  @StorePromoCode.Getter
  private getPromoCampaignListLoadingStatus!: LoadingStatusType;

  get selectedPromoCampaignCondition() {
    if (this.selectedRequest.id == 0) {
      return "";
    }
    return GetPromoCodeConditionText(this.selectedRequest.conditionList);
  }

  @StorePromoCodeRequest.Mutation
  private UpdateCampaignSelectByCreate!: ({
    campaignIndex,
    value,
  }: {
    campaignIndex: number;
    value: Record<string, any>;
  }) => void;

  @StorePromoCodeRequest.Mutation
  private ValidateAllCampaigns!: () => void;

  @StorePromoCodeRequest.Action
  private UpdateAmountDiscount!: ({
    discountValue,
    campaignIndex,
  }: {
    discountValue: number;
    campaignIndex: number;
  }) => void;

  mounted() {
    this.renderDropDown = true;
    this.selectedRequest = this.requestDetail.campaign.value;
  }

  private DoValidateAllCampaigns() {
    this.ValidateAllCampaigns();
  }

  @Watch("selectedRequest", { deep: true })
  SelectedRequestChanged(newVal: PromoCampaignModel) {
    const input = {
      campaignIndex: this.campaignIndex,
      value: newVal,
    };
    this.UpdateCampaignSelectByCreate(input);
    const amountDiscountInput = {
      discountValue: newVal.discountNumber,
      campaignIndex: this.campaignIndex,
    };
    this.UpdateAmountDiscount(amountDiscountInput);
  }
}
