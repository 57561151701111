

















import { LoadingStatusType } from "@/enums/enums";
import { PromoCampaignModel } from "@/model/promoCode/promoCodeModel";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StorePromoCode = namespace("PromoCode");

@Component
export default class DropdownCampaign extends Vue {
  @Prop({}) campaignId!: number;
  @Prop({}) campaign!: any;
  @Prop({ default: false }) required!: boolean;
  @Prop({ default: false }) inputError!: boolean;

  private selectCampaign: PromoCampaignModel | null = null;
  private campaignList: Array<PromoCampaignModel> = [];

  @StorePromoCode.Getter
  private getPromoCampaignList!: Array<PromoCampaignModel>;
  @StorePromoCode.Getter
  private getPromoCampaignListLoadingStatus!: LoadingStatusType;

  @StorePromoCode.Action
  private DoGetPromoCampaignList!: () => void;

  @Watch("getPromoCampaignListLoadingStatus", { immediate: true })
  getPromoCampaignListLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      this.campaignList = this.getPromoCampaignList;
      this.DoMatchCampaign();
    } else if (newVal === LoadingStatusType.None) {
      this.DoGetPromoCampaignList();
    }
  }

  @Watch("selectCampaign", { deep: true })
  selectCampaignChanged(newValue: any) {
    if (newValue && this.getPromoCampaignListLoadingStatus === LoadingStatusType.Success) {
      if (newValue.id === 0) {
        this.inputError = true;
      } else {
        this.inputError = false;
      }
      this.$emit("update:inputError", this.inputError);
      this.$emit("update:campaignId", newValue.id);
      this.$emit("update:campaign", newValue);
    }
  }

  @Watch("campaignId")
  selectCampaignIdChanged(newVal: number, oldValue: number) {
    if (newVal !== oldValue) {
      this.DoMatchCampaign();
    }
  }

  public DoMatchCampaign() {
    if (this.campaignId > 0 && this.getPromoCampaignListLoadingStatus === LoadingStatusType.Success) {
      const selectCampaign = this.campaignList.find((x: PromoCampaignModel) => x.id === this.campaignId);
      if (selectCampaign) {
        this.selectCampaign = selectCampaign;
      }
    }
  }
}
