









































































































































import { CreatePromoCodeRequestType, LoadingStatusType, TableSorting, PromoStatus } from "@/enums/enums";
import {
  GetPromoCodeCreateTemplateDefault,
  GetPromoCampaignCreateTemplateDefault,
  GetPromoCodeRequestCreateBySelectTemplate,
  GetPromoCodeRequestCreateByCreateTemplate,
  GetPromoCodeRequestObjectTemplate,
  ValidatePromoCodeHeaderDetail,
  ConvertPromoRequestToDraftHeaderDetail,
} from "@/helper/promoCodeHelper";
import { NewObject, CheckIsExactDevelop, AddDetectTabClosing, RemoveDetectTabClosing } from "@/helper/shareHelper";
import {
  PromoCampaignModel,
  PromoCodeListRequestModel,
  PromoCodeModel,
  PromoCodeRequestDetailModel,
  PromoCodeRequestHeaderDetailModel,
  PromoDraftingIndexRequest,
  PromoCodeRequestCreateListModel,
} from "@/model/promoCode/promoCodeModel";
import { NavigationGuardNext } from "vue-router/types";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import PromoCodeRequestBySelect from "./PromoCodeRequestBySelect.vue";
import PromoCodeRequestByCreate from "./PromoCodeRequestByCreate.vue";
import PromoCodeCreateBasicAndAdvance from "./PromoCodeCreateBasicAndAdvance.vue";
import ModalAutoGenPromoCode from "./ModalAutoGenPromoCode.vue";
import AuthService from "@/service/authService";

const auth = new AuthService();
const StorePromoCode = namespace("PromoCode");
const StorePromoCodeRequest = namespace("PromoCodeRequest");
const StoreLoading = namespace("Loading");

Component.registerHooks(["beforeRouteLeave"]);

@Component({
  components: {
    PromoCodeRequestBySelect,
    PromoCodeRequestByCreate,
    PromoCodeCreateBasicAndAdvance,
    ModalAutoGenPromoCode,
  },
})
export default class PromoCodeRequestCreate extends Vue {
  private createPromoCodeRequestType = CreatePromoCodeRequestType;
  private LoadingStatusType = LoadingStatusType;
  private PromoStatus = PromoStatus;
  private renderBySelect = true;
  private fileInput = null;
  private referenceQuotation = null;
  private isShowModalAutoGenPromoCode = false;
  private draftRequestId = "";
  private isExactDevelop = CheckIsExactDevelop();
  private campaignRequestList: Array<Record<string, any>> = [];
  private isEditDraft = false;
  private isFormRender = false;
  private sentType = PromoStatus.None;
  private leaveSite = false;
  private headerDetail: PromoCodeRequestHeaderDetailModel = {
    requestNo: {
      isError: false,
      value: "",
    },
    asanaTask: {
      isError: false,
      value: "",
    },
    ref1: {
      isError: false,
      value: "",
    },
    requestDate: {
      isError: false,
      value: "",
    },
  };

  @StorePromoCode.Getter
  private getDraftPromoRequestLoading!: LoadingStatusType;
  @StorePromoCode.Getter
  private getPromoRequestLoading!: LoadingStatusType;
  @StorePromoCode.Getter
  private getRequestId!: number | null;

  @StorePromoCodeRequest.Getter
  private getIsCreatePromoCodeError!: boolean;
  @StorePromoCodeRequest.Getter
  private getCampaignList!: PromoCodeRequestCreateListModel;
  @StorePromoCodeRequest.Getter
  private getCampaignListLength!: number;
  @StorePromoCodeRequest.Action
  private SetDefaultCampaignList!: () => void;
  @StorePromoCodeRequest.Action
  private AddOneCampaignList!: () => void;
  @StorePromoCodeRequest.Action
  private RemoveCampaignList!: (index: number) => void;
  @StorePromoCodeRequest.Action
  private SetCampaignRequestType!: ({
    type,
    campaignIndex,
  }: {
    type: CreatePromoCodeRequestType;
    campaignIndex: number;
  }) => void;

  @StorePromoCode.Getter
  private getPromoRequestNumber!: any;
  @StorePromoCode.Getter
  private getPromoRequestNumberLoading!: LoadingStatusType;
  @StorePromoCode.Getter
  private getPromoRequest!: PromoCodeRequestDetailModel | null;
  @StorePromoCode.Getter
  private getEditPromoRequestLoading!: LoadingStatusType;
  @StorePromoCode.Getter
  private getPromoCampaignListLoadingStatus!: LoadingStatusType;
  @StorePromoCode.Action
  private DoGetPromoRequestNumber!: () => void;
  @StorePromoCode.Action
  private DoGetPromoRequestForDraft!: (input: PromoDraftingIndexRequest) => void;
  @StorePromoCode.Action
  private DoGetPromoCampaignList!: () => void;
  @StorePromoCode.Mutation
  private SetPromoRequestLoading!: (status: LoadingStatusType) => void;
  @StorePromoCode.Mutation
  private SetPromoRequestNumberLoading!: (status: LoadingStatusType) => void;

  @StorePromoCodeRequest.Action
  private DoSendDraftPromoRequest!: (input: Record<string, any>) => void;
  @StorePromoCodeRequest.Action
  private DoSendEditPromoRequest!: (input: Record<string, any>) => void;
  @StorePromoCodeRequest.Action
  private ResetAllPromoCodeRequest!: () => void;
  @StorePromoCodeRequest.Mutation
  private ValidatePromoCodeAllCampaignAllPromoCode!: () => void;
  @StorePromoCodeRequest.Mutation
  private UpdateCampaignListFromPromoRequest!: () => void;

  @StoreLoading.Action
  private IsLoading!: (input: boolean) => void;

  get isGetPromoRequestNumberLoading() {
    return this.getPromoRequestNumberLoading == LoadingStatusType.Loading;
  }

  get isDraftOrEditPromoCodeLoading() {
    return (
      this.getDraftPromoRequestLoading == LoadingStatusType.Loading ||
      this.getEditPromoRequestLoading == LoadingStatusType.Loading
    );
  }

  get isDraftOrEditPromoCodeLoadingFail() {
    return (
      this.getDraftPromoRequestLoading == LoadingStatusType.Fail ||
      this.getEditPromoRequestLoading == LoadingStatusType.Fail
    );
  }

  get isEditPromoRequestAndIsLoading() {
    return (
      this.isEditDraft &&
      (this.getPromoRequestLoading == LoadingStatusType.Loading ||
        this.getPromoCampaignListLoadingStatus == LoadingStatusType.Loading)
    );
  }

  get isButtonDisabled() {
    return this.isDraftOrEditPromoCodeLoading || this.isEditPromoRequestAndIsLoading;
  }

  async created() {
    this.ResetAllPromoCodeRequest();
    this.SetPromoRequestLoading(LoadingStatusType.None);
    this.SetPromoRequestNumberLoading(LoadingStatusType.None);
    const accessPermission = await auth.getPermissions();
    const isCanEdit = accessPermission["promoCode"]["create"];
    if (!isCanEdit) {
      this.$router.push({ name: "cannotAccess" });
    }
    this.draftRequestId = this.$route.query.draftRequestId ? (this.$route.query.draftRequestId as string) : "";
    this.isEditDraft = this.draftRequestId.length > 0;

    if (!this.isEditDraft) {
      this.SetDefaultCampaignList();
      this.DoGetPromoRequestNumber();
      this.SetRequestDate();
      this.isFormRender = true;
    } else {
      this.IsLoading(true);
      this.DoGetPromoCampaignList();
    }
    document.title = "Promo Code Request Create";
  }

  beforeDestroy() {
    document.title = "peakadminexprience";
  }

  mounted() {
    AddDetectTabClosing();
  }

  destroyed() {
    RemoveDetectTabClosing();
  }

  public AddCampaignRequest() {
    this.AddOneCampaignList();
  }

  public SetIsShowModalAutoGenPromoCode(value: boolean) {
    this.isShowModalAutoGenPromoCode = value;
  }

  public RemoveCampaignRequest(removeIndex: number) {
    this.renderBySelect = false;
    this.RemoveCampaignList(removeIndex);
    this.$nextTick(() => {
      this.renderBySelect = true;
    });
  }
  public SetHeaderDetail() {
    this.headerDetail = ConvertPromoRequestToDraftHeaderDetail(this.getPromoRequest);
  }

  @Watch("getPromoRequestLoading")
  changedGetPromoRequestLoading(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Success) {
      if (this.getPromoRequest && this.getPromoRequest.status < PromoStatus.Approve) {
        this.SetHeaderDetail();
      } else {
        alert("ไม่สามารถแก้ไข Request ได้");
        this.IsLoading(false);
        this.leaveSite = true;
        this.$router.push("promoCodeRequest");
      }
    }
  }

  @Watch("getPromoCampaignListLoadingStatus")
  getPromoCampaignListLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Success && this.isEditDraft) {
      const input = {
        requestId: this.draftRequestId,
      };
      this.DoGetPromoRequestForDraft(input);
      this.isFormRender = true;
    }
  }

  @Watch("getPromoRequestNumberLoading", { immediate: true })
  getPromoRequestNumberLoadingChanged() {
    if (this.getPromoRequestNumberLoading == LoadingStatusType.Success) {
      this.headerDetail.requestNo.value = this.getPromoRequestNumber;
    }
  }

  @Watch("getDraftPromoRequestLoading")
  getDraftPromoRequestLoadingChanged() {
    if (this.getDraftPromoRequestLoading == LoadingStatusType.Success) {
      this.AlertSentSuccess();
      this.$router.push({
        name: "promoCodeRequestDetail",
        query: { requestId: String(this.getRequestId) },
      });
    }
  }

  @Watch("getEditPromoRequestLoading")
  getEditPromoRequestLoadingChanged() {
    if (this.getEditPromoRequestLoading == LoadingStatusType.Success) {
      this.AlertSentSuccess();
      this.$router.push({
        name: "promoCodeRequestDetail",
        query: { requestId: String(this.draftRequestId) },
      });
    }
  }

  @Watch("isEditPromoRequestAndIsLoading")
  isEditPromoRequestAndIsLoadingChanged() {
    if (!this.isEditPromoRequestAndIsLoading) {
      this.SetIsLoadingFalseAfterCompleteRender();
    }
  }

  @Watch("isDraftOrEditPromoCodeLoading")
  isDraftOrEditPromoCodeLoadingChanged() {
    if (this.isDraftOrEditPromoCodeLoading) {
      this.IsLoading(true);
    }
  }

  @Watch("isDraftOrEditPromoCodeLoadingFail")
  isDraftOrEditPromoCodeLoadingFailChanged() {
    if (this.isDraftOrEditPromoCodeLoadingFail) {
      this.leaveSite = false;
      this.IsLoading(false);
    }
  }
  private AlertSentSuccess() {
    let message = "";
    switch (this.sentType) {
      case PromoStatus.Draft:
        message = !this.isEditDraft ? "บันทึก Draft สำเร็จ!" : "แก้ไข Draft สำเร็จ!";
        break;
      case PromoStatus.WaitingApprove:
        message = "ส่ง Approve สำเร็จ!";
        break;
    }
    alert(message);
  }

  private BackToTableList() {
    this.$router.push({ name: "promoCodeRequest" });
  }

  private OnChangeValidatePromoCodeHeaderDetail() {
    ValidatePromoCodeHeaderDetail(this.headerDetail);
  }

  private SetRequestDate() {
    const todayDate = new Date();
    this.headerDetail.requestDate.value = todayDate.toISOString().split("T")[0].ConvertToDateFormat();
  }

  private SendPromoCodeRequest(status: PromoStatus) {
    this.ValidatePromoCodeAllCampaignAllPromoCode();
    this.sentType = status;
    if (!ValidatePromoCodeHeaderDetail(this.headerDetail) && !this.getIsCreatePromoCodeError) {
      this.leaveSite = true;
      const input = {
        requestNumber: this.headerDetail.requestNo.value,
        status: status,
        asanaTask: this.headerDetail.asanaTask.value,
        reference1: this.headerDetail.ref1.value,
        fileUrl: this.fileInput == null ? "" : URL.createObjectURL(this.fileInput),
        referenceQuotation: this.referenceQuotation ? this.referenceQuotation : null,
        date: this.headerDetail.requestDate.value.ConvertToDate(),
        requestId: this.draftRequestId,
      };
      if (this.isEditDraft) {
        if (status == PromoStatus.WaitingApprove) {
          confirm("ยืนยันส่ง Approve หรือไม่?") && this.DoSendEditPromoRequest(input);
        } else {
          confirm("ยืนยันแก้ไข Draft ข้อมูลหรือไม่?") && this.DoSendEditPromoRequest(input);
        }
      } else {
        if (status == PromoStatus.WaitingApprove) {
          confirm("ยืนยันส่ง Approve หรือไม่?") && this.DoSendDraftPromoRequest(input);
        } else {
          confirm("ยืนยันบันทึก Draft ข้อมูลหรือไม่?") && this.DoSendDraftPromoRequest(input);
        }
      }
    }
  }

  public UpdateValuePromoCodeRequestListItem({ campaignIndex, promoCodeItemIndex, key, value }: Record<string, any>) {
    if (key != "remark") {
      this.campaignRequestList[campaignIndex].promoCodeList[promoCodeItemIndex][key].value = value;
    } else {
      this.campaignRequestList[campaignIndex].promoCodeList[promoCodeItemIndex][key] = value;
    }
  }

  private SetCampaignTemplate(type: CreatePromoCodeRequestType, campaignIndex: number) {
    this.SetCampaignRequestType({ type, campaignIndex });
  }

  private SetIsLoadingFalseAfterCompleteRender() {
    this.IsLoading(false);
  }

  beforeRouteLeave(to: any, from: any, next: NavigationGuardNext) {
    if (this.leaveSite) {
      next();
    } else {
      if (confirm("ต้องการออกจากหน้า Create หรือไม่?")) {
        next();
      }
    }
  }
}
