

































































































































































































































import { Vue, Component, Prop, Watch, PropSync } from "vue-property-decorator";
import { PromoCodeCreateLevelType } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";
import { IsNumber, IsLengthOver, IsEmpty, IsZero } from "@/helper/textInputValidateHelper";
import { namespace } from "vuex-class";
import { PromoCodeRequest } from "../../../model/promoCode/promoCodeModel";
import { Store } from "vuex";

const StorePromoCodeRequest = namespace("PromoCodeRequest");
@Component({ components: {}, name: "PromoCodeCreateBasicAndAdvance" })
export default class PromoCodeCreateBasicAndAdvance extends Vue {
  @Prop() campaignIndex!: number;
  @Prop() promoCodeDetail!: Array<PromoCodeRequest>;
  @Prop() promoCodeCreateLevel!: PromoCodeCreateLevelType;

  private PromoCodeCreateLevelType = PromoCodeCreateLevelType;
  private IsNumber = IsNumber;
  private IsLengthOver = IsLengthOver;
  private IsEmpty = IsEmpty;
  private IsZero = IsZero;

  @StorePromoCodeRequest.Getter
  private getPromoCodeList!: (campaignIndex: number) => (campaignIndex: number) => Array<PromoCodeRequest>;
  @StorePromoCodeRequest.Getter
  private getPromoCodeListLength!: (campaignIndex: number) => (campaignIndex: number) => number;
  @StorePromoCodeRequest.Getter
  private getPromoCodeCreateLevel!: (campaignIndex: number) => (campaignIndex: number) => PromoCodeCreateLevelType;
  @StorePromoCodeRequest.Action
  private AddPromoCode!: (campaignIndex: number) => void;
  @StorePromoCodeRequest.Action
  private RemovePromoCode!: ({
    campaignIndex,
    promoCodeIndex,
  }: {
    campaignIndex: number;
    promoCodeIndex: number;
  }) => void;
  @StorePromoCodeRequest.Action
  private ResetPromoCodeCheckbox!: (campaignIndex: number) => void;
  @StorePromoCodeRequest.Action
  private UpdatePromoCode!: ({
    campaignIndex,
    promoCodeListIndex,
    key,
    value,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
    key: string;
    value: string;
  }) => void;
  @StorePromoCodeRequest.Action
  private UpdatePromoCodeStartDate!: ({
    campaignIndex,
    promoCodeListIndex,
    value,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
    value: string;
  }) => void;
  @StorePromoCodeRequest.Action
  private UpdatePromoCodeExpireDate!: ({
    campaignIndex,
    promoCodeListIndex,
    value,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
    value: string;
  }) => void;
  @StorePromoCodeRequest.Action
  private SetCheckBoxIsCheck!: ({
    campaignIndex,
    promoCodeListIndex,
    key,
    value,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
    key: string;
    value: any;
  }) => void;
  @StorePromoCodeRequest.Action
  private SetCheckboxValue!: ({
    campaignIndex,
    promoCodeListIndex,
    key,
    value,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
    key: string;
    value: any;
  }) => void;

  @StorePromoCodeRequest.Action
  private SetDateModalOpen!: ({
    campaignIndex,
    promoCodeListIndex,
    key,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
    key: "startDate" | "expireDate";
  }) => void;

  @StorePromoCodeRequest.Action
  private SetDateModalClose!: ({
    campaignIndex,
    promoCodeListIndex,
    key,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
    key: "startDate" | "expireDate";
  }) => void;

  @StorePromoCodeRequest.Action
  private SetUpdatePromoCodeCreateLevel!: (input: { type: PromoCodeCreateLevelType; campaignIndex: number }) => void;

  @StorePromoCodeRequest.Mutation
  private ValidatePromoCodeOneCampaignOnePromoCode!: ({
    campaignIndex,
    promoCodeListIndex,
  }: {
    campaignIndex: number;
    promoCodeListIndex: number;
  }) => void;

  private SetPromoCodeLevel(value: PromoCodeCreateLevelType) {
    const input = {
      type: value,
      campaignIndex: this.campaignIndex,
    };
    this.SetUpdatePromoCodeCreateLevel(input);
    this.ResetPromoCodeCheckbox(this.campaignIndex);
  }

  private RemoveCodeList(campaignIndex: number, itemIndex: number) {
    const input = { campaignIndex, promoCodeIndex: itemIndex };
    this.RemovePromoCode(input);
  }

  private AddCodeList(campaignIndex: number) {
    this.AddPromoCode(campaignIndex);
  }

  private SetPromoCodeValue(promoCodeListIndex: number, value: string) {
    const input = {
      campaignIndex: this.campaignIndex,
      promoCodeListIndex,
      key: "promoCode",
      value,
    };
    this.UpdatePromoCode(input);
  }

  private SetQuotaValue(promoCodeListIndex: number, value: string) {
    const input = {
      campaignIndex: this.campaignIndex,
      promoCodeListIndex,
      key: "quota",
      value,
    };
    this.UpdatePromoCode(input);
  }

  private SetRemarkValue(promoCodeListIndex: number, value: string) {
    const input = {
      campaignIndex: this.campaignIndex,
      promoCodeListIndex,
      key: "remark",
      value,
    };
    this.UpdatePromoCode(input);
  }

  private UpdateStartDate(campaignIndex: number, promoCodeListIndex: number, value: string) {
    this.UpdatePromoCodeStartDate({ campaignIndex, promoCodeListIndex, value });
    const clonePromoCodeList = NewObject(this.promoCodeDetail);
    const expireDateValue = clonePromoCodeList[promoCodeListIndex].expireDate.value;
    if (!expireDateValue) {
      const date = new Date(clonePromoCodeList[promoCodeListIndex].startDate.value);
      const value = new Date(date.setDate(date.getDate() + 1)).toISOString().slice(0, 10);
      this.UpdatePromoCodeExpireDate({ campaignIndex, promoCodeListIndex, value });
    }
  }

  private UpdateExpireDate(campaignIndex: number, promoCodeListIndex: number, value: string) {
    this.UpdatePromoCodeExpireDate({ campaignIndex, promoCodeListIndex, value });
  }

  private DoSetDateModalOpen(campaignIndex: number, promoCodeListIndex: number, key: "startDate" | "expireDate") {
    const input = {
      campaignIndex,
      promoCodeListIndex,
      key,
    };
    this.SetDateModalOpen(input);
  }

  private DoSetDateModalClose(
    campaignIndex: number,
    promoCodeListIndex: number,
    key: "startDate" | "expireDate",
    isCancel: boolean
  ) {
    const input = {
      campaignIndex,
      promoCodeListIndex,
      key,
    };
    this.SetDateModalClose(input);
    if (isCancel) {
      const clonePromoCodeList = NewObject(this.promoCodeDetail);
      const currentValue = clonePromoCodeList[promoCodeListIndex][key].value;
      if (key == "startDate" && !currentValue) {
        this.UpdatePromoCodeStartDate({ campaignIndex, promoCodeListIndex, value: "" });
      } else if (key == "expireDate" && !currentValue) {
        this.UpdatePromoCodeExpireDate({ campaignIndex, promoCodeListIndex, value: "" });
      }
    }
  }

  private OnChangedCreatePromoCode(campaignIndex: number, promoCodeListIndex: number) {
    const input = { campaignIndex, promoCodeListIndex };
    this.ValidatePromoCodeOneCampaignOnePromoCode(input);
  }

  private UpdateCheckBoxIsCheck(campaignIndex: number, promoCodeListIndex: number, key: string, value: any) {
    const input = {
      campaignIndex,
      promoCodeListIndex,
      key,
      value,
    };
    this.SetCheckBoxIsCheck(input);
    if (!input.value) {
      this.SetCheckboxValue({ ...input, value: "" });
    }
  }

  private UpdateCheckBoxValue(campaignIndex: number, promoCodeListIndex: number, key: string, value: any) {
    const input = {
      campaignIndex,
      promoCodeListIndex,
      key,
      value,
    };
    this.SetCheckboxValue(input);
  }

  private GetDateFormat(text: string) {
    return !text ? text : text.ConvertToDateFormat();
  }
}
